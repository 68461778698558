import { createRouter, createWebHistory } from 'vue-router'
import store from '../store'
import functions from '../common/functions'

const routes = [
    {
        path: '/index',
        name: 'index',
        meta: { title: '智能中医大脑' },
        component: () => import("../views/index.vue")
    },
    {
        path: '/',
        redirect: { path: '/index' }
    },
    {
        path: '/auth',
        name: 'auth',
        meta: { title: '等待授权中...' },
        component: () => import("../views/auth.vue")
    },
    {
        path: '/login',
        name: 'login',
        meta: { title: '正在登录...' },
        component: () => import("../views/login.vue")
    },
    {
        path: '/baseinfo',
        name: 'baseinfo',
        meta: { title: '基础信息' },
        component: () => import("../views/baseinfo.vue")
    },
    {
        path: '/step1',
        name: 'step1',
        meta: { title: '面象采集' },
        component: () => import("../views/step1.vue")
    },
    {
        path: '/step2',
        name: 'step2',
        meta: { title: '舌象采集' },
        component: () => import("../views/step2.vue")
    },
    {
        path: '/step3',
        name: 'step3',
        meta: { title: '声音采集' },
        component: () => import("../views/step3.vue")
    },
    {
        path: '/step4',
        name: 'step4',
        meta: { title: '问卷调查' },
        component: () => import("../views/step4.vue")
    },
    {
        path: '/report',
        name: 'report',
        meta: { title: '个人健康评测报告' },
        component: () => import("../views/report.vue")
    },
    {
        path: '/single',
        name: 'single',
        meta: { title: '单科评测报告' },
        component: () => import("../views/single.vue")
    },
    {
        path: '/evaluation',
        name: 'evaluation',
        meta: { title: '单科评测页' },
        component: () => import("../views/evaluation.vue")
    },
    {
        path: '/healthrecord',
        name: 'healthrecord',
        meta: { title: '基本信息页' },
        component: () => import("../views/healthrecord.vue")
    },
    {
        path: '/history',
        name: 'history',
        meta: { title: '历史报告页' },
        component: () => import("../views/history.vue")
    },
    {
        path: "/:catchAll(.*)",
        redirect: { path: '/index' }
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

router.beforeEach((to, from, next) => {
    let debug = new functions.DEBUG('router change')
    debug.log('init', { from: from.fullPath, to: to.fullPath })
    if (store.state.open_id == false || store.state.user_id == false) {
        if (to.name == 'auth' || to.name == 'login') {
            debug.log('finish', { result: to.name })
            debug.finish()
            next()
            return;
        } else {
            debug.finish()
            debug.log('finish', { result: '/auth' })
            next({
                path: '/auth',
                replace: true,
                query: {
                    path: encodeURIComponent(to.fullPath)
                }
            })
            return;
        }
    }
    next()
    debug.log('finish', { result: to.name })
    debug.finish()
})

router.afterEach((to, from) => {
    document.title = to.meta.title
})
export default router
